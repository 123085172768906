/* eslint-disable */
<template>
  <div
    class="main-wrapper-area row"
    v-if="
      typeof getUserData.user !== 'undefined' &&
        Object.keys(getUserData.user).length
    "
  >
    <admin-header-header-vue
      v-if="
        getUserData.userRoles == 'super-admin'
      "
    ></admin-header-header-vue>
    <admin-header-header-vue
      v-if="
        getUserData.userRoles == 'admin'
      "
    ></admin-header-header-vue>
    <default-header-vue v-else></default-header-vue>
  </div>
</template>
<script>
import admin_header from "./admin_header";
import default_header from "./default_header";

export default {
  data() {
    return {
      pre: window.pre,
      homepre: window.pre
    };
  },
  components: {
    "admin-header-header-vue": admin_header,
    "default-header-vue": default_header
  },
  computed: {
    getUserData() {
      return this.$store.state.auth;
    }
  }
};
</script>
